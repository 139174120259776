import React from "react"
import { Link, graphql } from "gatsby"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

class TopicTemplate extends React.Component {
  render() {
    const { previous,tag,tagPostId, tagslug, next, relatedposts } = this.props.pageContext
    const post = this.props.data.postsJsonMarkdown
    return (
      <Layout location={this.props.location} title={tag}>
        <Seo
          title={tag+" Tutorial - "+post.Title}
          description={tag+" Tutorial - "+post.Title}
        />
        <div class="flex h-full flex-row">
          <div class="flex w-1/5 overflow-hidden h-screen sticky top-0">
            <div class="overflow-y-scroll border-r border-black">
              <nav>
                <ul>
                  {relatedposts.map(post => (
                      <li className="border-t border-black m-0 p-0">
                          <Link
                          key={post.node.Id}
                          className="shadow-none block py-2 px-4"
                          to={tagPostId === post.node.Id?'/'+tagslug:'/'+tagslug+'/doc/'+post.node.Id+'/'+post.node.Title.toLowerCase().replace(/[^a-z0-9 -]/g, '-')
                          .replace(/\s+/g, '-')
                          .replace(/-+/g, '-')}
                          activeClassName="text-red-500"
                        >{post.node.Title}</Link>
                      </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
          <article className="flex-1  w-4/5 flex flex-col p-4 pr-20 pl-10">
            <header>
              <Link
              to={'/'+tagslug}
              ><span className="text-gray-500">{tag}</span></Link>
              <h1 className="text-5xl font-black mt-2 mb-0">
                {post.Title}
              </h1>
            </header>
            <section
              className="markdown"
              dangerouslySetInnerHTML={{ __html: post.childMarkdownRemark.html }}
            />
            <hr className="h-px mb-8" />
            <nav>
            <ul
              className="flex flex-wrap justify-between mb-8"
              // style={{
              //   display: `flex`,
              //   flexWrap: `wrap`,
              //   justifyContent: `space-between`,
              //   listStyle: `none`,
              //   padding: 0,
              // }}
            >
              <li>
                {previous && (
                  <Link
                    className="text-blue-600"
                    to={'/'+tagslug+'/doc/'+previous.node.Id+'/'+previous.node.Title.toLowerCase().replace(/[^a-z0-9 -]/g, '-')
                    .replace(/\s+/g, '-')
                    .replace(/-+/g, '-')}
                    rel="prev"
                  >
                    ← {previous.node.Title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link
                    className="text-blue-600"
                    to={'/'+tagslug+'/doc/'+next.node.Id+'/'+next.node.Title.toLowerCase().replace(/[^a-z0-9 -]/g, '-')
                    .replace(/\s+/g, '-')
                    .replace(/-+/g, '-')}
                    rel="next"
                  >
                    {next.node.Title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
          </article>
        </div>
        
        </Layout>
    )
  }
}

export default TopicTemplate

export const postQuery = graphql`
query BlogPostBySlug($postId: Int!) {
  postsJsonMarkdown( Id: { eq: $postId }) {
    Id
    Title
    DocTagId
    CreationDate
    childMarkdownRemark {
      html
    }
  }
}
`